/* stylelint-disable-next-line at-rule-disallowed-list */
@import 'https://fonts.googleapis.com/css?family=Barlow:wght@400,600,700&display=swap';
/* stylelint-disable-next-line at-rule-disallowed-list */
@import 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap';

* {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  @apply font-secondary;

  font-size: theme('fontSize.14');
  font-weight: theme('fontWeight.normal');
  letter-spacing: 0;
  line-height: 1.125rem;
  color: theme('colors.base-content.low');
}
