.catalog-viewer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: theme('colors.base.400') !important;
  max-height: 100%;

  .s7ecatalogviewer {
    padding-top: theme('spacing.14');
    bottom: 0;
    left: 0;
    max-height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    .s7socialshare,
    .s7embedshare,
    .s7favoritesmenu,
    .s7firstpagebutton,
    .s7lastpagebutton,
    .s7toolbarleftbutton,
    .s7toolbarrightbutton {
      display: none !important;
    }

    .s7tableofcontents {
      .s7panel {
        bottom: theme('spacing.6') !important;
        top: auto !important;
      }
    }

    .s7infopanelpopup,
    .s7pageview {
      background: theme('colors.base.400') !important;
      height: 100% !important;
      width: 100% !important;
    }

    .s7pageindicator {
      color: theme('colors.base.100');
    }

    .s7controlbar {
      background-color: theme('colors.primary.500');
      bottom: 0 !important;
      top: auto !important;
      width: 100% !important;
    }

    .s7container {
      height: 100% !important;
    }
  }
}

.navbar {
  height: 2.75rem;
  background-color: theme('colors.base.200');
  border-color: theme('colors.base.300');
  padding: 0 0.9375rem;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  &__container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__brand-title {
    color: #777;
    font-size: theme('fontSize.18');
    margin-left: theme('spacing.4');
  }

  &__logo {
    height: 1.875rem;
  }
}
